import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import '../App.css'



const Websitelist = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState([]);

  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please upload a CSV file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const response = await axios.post('http://45.159.220.82:3000/api/upload-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setStatus(response.data);
    } catch (error) {
      console.error('Error uploading file', error);
      alert('Error uploading file');
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [page]);

  const fetchReports = async () => {
    try {

      const response = await axios.get('http://45.159.220.82:3000/api/reports', {
        params: {
          limit,
          page
        }
      });
      setReports(response.data.data);
      setTotalPages(response.data.totalPages);

    } catch (error) {
      console.error('Error fetching reports', error);
      alert('Error fetching reports');
    }
  };
  const serverUrl = 'http://45.159.220.82:3000';





  return (
    <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
        <div className='main-content'>
          {/* <h2>Lighthouse Report Generator</h2> */}

          <div className="row r">
            <div className="col">
              <div className="title">
                <h2>All Campaign Website List</h2>
              </div>
              <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    Menu
                  </li>
                  <li className="breadcrumb-item active text-danger" aria-current="page">
                    All Campaign Website List
                  </li>

                </ol>
              </nav>

            </div>
            <div className="col text-end">

              <form onSubmit={handleSubmit}>
                <input type="file" accept=".csv" onChange={handleFileChange} />
                <button className='btn btn-primary' type="submit" disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload CSV'}
                </button>
              </form>              
            </div>

          </div>
          <div className='pb-4'>
            <div className="text-center pt-4">
              <h4>Search</h4>
            </div>
            <div className="row container">
              <div className="col">
                <div className="row">
                  <div className="col-auto"><label htmlFor="" className='pt-2'>Campaign List </label></div>
                  <div className="col-6">
                    <select className="form-select" aria-label="Default select example">
                      <option selected>Select List...</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-auto"> <label htmlFor="" className='pt-2'>From date</label></div>
                  <div className="col"> <input type="date" className='form-control' /></div>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-auto"> <label htmlFor="" className='pt-2'>To date</label></div>
                  <div className="col"> <input type="date" className='form-control' /></div>
                </div>
              </div>
            </div>
            <div className="row pt-3 container">
              <div className="col">
                <div className="row">
                  <div className="col-auto"> <label htmlFor="" className='pt-2'>Search by Keywords</label></div>
                  <div className="col-5"> <input type="text" className='form-control' placeholder='Search...' /></div>
                </div>
              </div>
              <div className="col"><button type="button" class="btn btn-primary float-end">Go</button></div>

            </div>



          </div>

          <div className="reports-table mt-3">
            <h2>Reports Data</h2>
            <div className='table-responsive'>
            {status.length > 0 && (
                <div className="status-container mt-3">
                  <h2>Processing Status</h2>
                  <ul>
                    {status.map((entry, index) => (
                      <li key={index}>
                        <strong>{entry.website}</strong> ({entry.email}): {entry.status}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <table className='table'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Website</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Report</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((report) => (
                    <tr key={report.id}>
                      <td>{report.id}</td>
                      <td>{report.url}</td>
                      <td>{report.email}</td>
                      <td>{report.status}</td>
                      <td>{report.created_at}</td>
                      <td><a href={`${serverUrl}/${report.pdf_path}`} target='_blank'>View</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <nav aria-label="...">
                  <ul class="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => setPage(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>




              <nav>
                <ul className="pagination">

                </ul>
              </nav>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Websitelist;