// src/components/Contact.js
import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const emailtemplet = () => {
  return (
    <div>
          <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
          <div className="main-content">
        
  
        <div class="page-header">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="title">
                            <h4>Email Templet</h4>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Menu
                            </li>
                            <li className="breadcrumb-item active text-danger" aria-current="page">
                            Email Templet
                            </li>
                            
                        </ol>
                    </nav>
                    </div>
                    <div className="col">
                    <button type="button" class="btn btn-primary float-end"> Add new email templet</button>
                    </div>
                    
                </div>
            </div>
            <div className="p-2"></div>
            <div className="page-body">
                
        
               <div className="titel-hrader">

               </div>
                <table class="table table-borderless">
                <thead>
                    <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Email templet name</th>
                    <th scope="col">Email Templet Title</th>
                    <th scope="col">Email templet Body message</th>
                    <th scope="col">Action</th>
                   
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
                </table>
      
            </div>

            
            </div>
        </div>
      </div>
   
    </div>
  );
};

export default emailtemplet;
