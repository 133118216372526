// src/components/Contact.js
import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const datalist = () => {
  return (
    <div>
     <div className="Website-list">
      <div className="col">
        <Navbar />
      </div>
      <div>
        <div className="pt-4"></div>
        <Sidebar />
        <div className="pt-5"></div>
          <div className="main-content">
        
  
                <div class="page-header">
						<div class="row">
							<div class="col-md-6 col-sm-12">
								<div class="title">
									<h4>Campaign Data List</h4>
								</div>
                                <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            Menu
                                        </li>
                                        <li className="breadcrumb-item active text-danger" aria-current="page">
                                        Campaign Data List
                                        </li>
                                        
                                    </ol>
                                </nav>
							</div>
                           
							
						</div>
					</div>
                    <div className="p-2"></div>
                    <div className="page-body">
                        
                
                       <div className="titel-hrader">

                       </div>
                        <table class="table table-borderless">
                        <thead>
                            <tr>
                            <th scope="col">Created date</th>
                            <th scope="col">Compaign name</th>
                            <th scope="col"> Action</th>
                           
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                        </table>
              
                    </div>

                    
                    </div>
        </div>
      </div>
 
    
    </div>
  );
};

export default datalist;
