// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './FormComponent.css'
import Login from './components/Login';
import Authentication from './components/authentication';

import Home from './home';
import Websitelist from './components/websitelist';
import Datalist from './components/data-list';
import Emailtemplet from './components/email-templet';
import SMPT from './components/smpt';

function App() {
  return (
    <Router>
   
    
   

    <Routes>
      
      <Route path="/" element={<Home />} />
      <Route path="/login" component={Login}  element={<Login />} />
      {/* <Route path="/" element={<Websitelist />} /> */}
      <Route path="/website-list" element={<Authentication element={Websitelist} />} />
      <Route path="/data-list" element={<Authentication element={Datalist} />} />
      <Route path="/email-templet" element={<Authentication element={Emailtemplet} />} />
      <Route path="/smpt" element={<Authentication element={SMPT} />} />
    </Routes>
  </Router>
  );
}

export default App;
