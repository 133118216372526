// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {

    event.preventDefault();

    try {
      const response = await axios.post('http://45.159.220.82:3000/api/login', { email, password });

      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
      
        alert('Login successful!');
  
          navigate('/website-list');
      
        
      } else {
        alert('Login failed!');
      }
    } catch (error) {

      console.error('There was an error!', error);
      alert('incorrect. Please try again.');
    }
  };


  return (
    <div>
      <section class="vh-100 section">
        <div class="container py-5 h-100" >
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-2-strong shadow-lg">
                <div class="card-body p-5 text-center">
                  <h3 class="mb-5 text-white">Sign in</h3>
                  <div className='d-flex justify-content-center p-3'>
                    <form onSubmit={handleLogin} style={{ width: '490px' }}>
                      <div className="form-group floating-label mb-4">
                        <input
                          type="email"
                          className="form-control input"
                          name="email" id="email"
                          placeholder=''
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="email" className='label'>Email</label>
                      </div>
                      <div className="form-group floating-label mb-4">
                        <input
                          type="password"
                          className="form-control border-bottom input"
                          id="password"
                          name="password"
                          value={password}
                          placeholder=''
                          onChange={(e) => setPassword(e.target.value)}
                          required />
                        <label htmlFor="website" className='label'>Password</label>
                      </div>                      
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      <div class="d-grid gap-2">
                        <button class="btn btn-primary" type="submit" disabled={loading}> {loading ? 'Login...' : 'Login'}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
