import React from 'react';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
const Sidebar = ({ setAuth }) => {

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
};

  return (
    <div>
    
      
    <div className="bg-dark text-white p-3" style={{ height: '100vh', width: '250px', position: 'fixed' }}>
    <nav className="navbar flex-column bg-dark text-white" data-bs-theme="dark">
          
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link active" to="/website-list">All Campaign Website List</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/data-list">Campaign data list</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/email-templet">Email Templet</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/smpt">SMTP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active"onClick={handleLogout}>Log Out</Link>
              </li>
            </ul>
          
        </nav>
    
    </div>
     
    
      {/* <h4 className="mb-4">COREUI</h4>
      <Nav className="flex-column">
        <LinkContainer to="/">
          <Nav.Link className="text-white">Dashboard</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/users">
          <Nav.Link className="text-white">Users</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/settings">
          <Nav.Link className="text-white">Settings</Nav.Link>
        </LinkContainer>
      </Nav> */}
    </div>
  );
};

export default Sidebar;